@font-face {
  font-family: "IBMPlexSansBold";
  font-style: normal;
  font-weight: normal;
  src: local("IBMPlexSansBold"), url("IBMPlexSans-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "IBMPlexSansSemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("IBMPlexSansSemiBold"),
    url("IBMPlexSans-SemiBold.woff") format("woff");
  font-display: swap;
}

/* @font-face {
  font-family: "BDOGroteskMedium";
  font-style: normal;
  font-weight: normal;
  src: url("BDOGrotesk-Medium.otf") format("otf");
  font-display: swap;
}

@font-face {
  font-family: "BDOGroteskRegular";
  font-style: normal;
  font-weight: normal;
  src: url("BDOGrotesk-Regular.otf") format("otf");
  font-display: swap;
} */
