body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #000; */
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}
*::-webkit-scrollbar-track {
  background: #e4e4e4;
}
*::-webkit-scrollbar-thumb {
  background: #3d98ec;
  border-radius: 16px;
}

@font-face {
  font-family: "BDOGroteskMedium";
  font-style: normal;
  font-weight: normal;
  src: url("../src/Assets/Fonts/BDOGrotesk-Medium.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "BDOGroteskRegular";
  font-style: normal;
  font-weight: normal;
  src: url("../src/Assets/Fonts/BDOGrotesk-Regular.otf") format("opentype");
  font-display: swap;
}
